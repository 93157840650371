<template>
    <!--新增对话框-->
    <el-dialog title="订 单 轨 迹" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose"
        width="100%" top="0">
        <div :id="'mapContainer' + billId"></div>
    </el-dialog>
</template>

<script>
import ty from "@/utils/ty";
import TMap from "@/utils/initMap";
export default {
    data() {
        return {
            imgVisible: false,
            dialogVisible: false,
            detail: {},
            tMap: null,
            billId: null,

        }
    },
    methods: {
        init(billId) {
            this.billId = billId
            this.dialogVisible = true;
            this.$axios.post('/admin/bill/orderTrajectory', { billId: billId }).then(res => {
                this.detail = res.data
                this.initMap()
            })
        },

        handleClose() {
            this.detail = {}
            document.getElementById('mapContainer' + this.billId).innerHTML = ''
            this.dialogVisible = false
        },
        initMap() {
            TMap.init().then((TMap) => {
                this.tMap = new TMap.Map('mapContainer' + this.billId, {
                    center: new TMap.LatLng(this.detail.vehicle ? Number(this.detail.vehicle.lat) : Number(this.detail.start.lat), this.detail.vehicle ? Number(this.detail.vehicle.lon) : Number(this.detail.start.lon)),//地图显示中心点
                    zoom: 9	//缩放级别
                });
            });
            this.cb()
        },
        //浏览器调用WebServiceAPI需要通过Jsonp的方式，此处定义了发送JOSNP请求的函数
        jsonp_request(url) {
            var script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
            console.log(script.src)
        },

        //定义请求回调函数，在此拿到计算得到的路线，并进行绘制
        cb(ret) {
            TMap.init().then((TMap) => {
                var that = this
                var pl = [];
                var line = [];
                var numindex = 0
                if (that.detail.detail) {
                    that.detail.detail.forEach((item, index) => {
                        pl.push(new TMap.LatLng(Number(item.lat), Number(item.lon)));
                        line.push({
                            "id": 'centre' + index,
                            "styleId": 'centre',
                            "position": new TMap.LatLng(Number(item.lat), Number(item.lon)),
                            'time': item.time,
                            "properties": {
                                "title": "marker"
                            }
                        })
                    })
                } else {
                    var routors = that.detail.routes
                    var coords = JSON.parse(routors)[0].polyline;
                    console.log(coords)
                    // 坐标解压（返回的点串坐标，通过前向差分进行压缩）
                    var kr = 1000000;
                    for (var i = 2; i < coords.length; i++) {
                        coords[i] = Number(coords[i - 2]) + Number(coords[i]) / kr;
                    }
                    for (var i = 0; i < coords.length; i += 2) {
                        pl.push(new TMap.LatLng(coords[i], coords[i + 1]));
                    }
                }
                line.unshift({
                    "id": 'start',
                    "styleId": 'start',
                    "position": new TMap.LatLng(Number(this.detail.start.lat), Number(this.detail.start.lon)),
                    'time': null,
                    "properties": {
                        "title": "marker"
                    }
                })
                line.push({
                    "id": 'end',
                    "styleId": 'end',
                    "position": new TMap.LatLng(Number(this.detail.end.lat), Number(this.detail.end.lon)),
                    'time': null,
                    "properties": {
                        "title": "marker"
                    }
                })
                if (this.detail.vehicle) {
                    line.push({
                        "id": 'vehicle',
                        "styleId": 'vehicle',
                        "position": new TMap.LatLng(Number(this.detail.vehicle.lat), Number(this.detail.vehicle.lon)),
                        'time': null,
                        "properties": {
                            "title": "marker"
                        }
                    })
                }
                console.log(line)
                //标记起终点marker
                var marker = new TMap.MultiMarker({
                    id: 'marker-layer',
                    map: that.tMap,
                    styles: {
                        "start": new TMap.MarkerStyle({
                            "width": 25,
                            "height": 35,
                            "anchor": { x: 16, y: 32 },
                            "src": 'https://mapapi.qq.com/web/miniprogram/demoCenter/images/marker-start.png'
                        }),
                        'centre': new TMap.MarkerStyle({
                            "width": 25,
                            "height": 35,
                            "anchor": { x: 16, y: 32 },
                            "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker_blue.png'
                        }),
                        'vehicle': new TMap.MarkerStyle({
                            "width": 25,
                            "height": 35,
                            "anchor": { x: 16, y: 32 },
                            "src": 'https://mapapi.qq.com/web/mapComponents/componentsTest/zyTest/static/model_taxi.png?a=1'
                        }),
                        "end": new TMap.MarkerStyle({
                            "width": 25,
                            "height": 35,
                            "anchor": { x: 16, y: 32 },
                            "src": 'https://mapapi.qq.com/web/miniprogram/demoCenter/images/marker-end.png'
                        })
                    },
                    geometries: line
                });
                if (pl.length > 0) {
                    console.log('p1')
                    console.log(pl)
                    console.log('p1')
                    that.display_polyline(pl)//显示路线
                }
                //初始化infoWindow
                var infoWindow = new TMap.InfoWindow({
                    map: this.tMap,
                    position: new TMap.LatLng(
                        this.detail.vehicle ? Number(this.detail.vehicle.lat) : Number(this.detail.detail[this.detail.detail.length - 1].lat),
                        this.detail.vehicle ? Number(this.detail.vehicle.lon) : Number(this.detail.detail[this.detail.detail.length - 1].lon)
                    ),
                    content: this.detail.vehicle ? this.detail.vehicle.time : this.detail.detail[this.detail.detail.length - 1].time,
                    offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素
                });
                marker.on("click", function (evt) {
                    if (evt.geometry.time) {
                        infoWindow.open(); //打开信息窗
                        infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
                        infoWindow.setContent(`<div>时间：${evt.geometry.time}</div> `);//设置信息窗内容
                    }
                })
            })
        },
        display_polyline(pl) {
            TMap.init().then((TMap) => {
                //创建 MultiPolyline显示折线
                var polylineLayer = new TMap.MultiPolyline({
                    id: 'polyline-layer', //图层唯一标识
                    map: this.tMap,//绘制到目标地图
                    //折线样式定义
                    styles: {
                        'style_blue': new TMap.PolylineStyle({
                            'color': '#FD760B', //线填充色
                            'width': 8, //折线宽度
                            'borderWidth': 0, //边线宽度
                            'borderColor': '#FFF', //边线颜色
                            'lineCap': 'round', //线端头方式
                            'showArrow': true,
                            'arrowOptions': {
                                'space': 40
                            },
                        })
                    },
                    //折线数据定义
                    geometries: [
                        {
                            'id': 'pl_1',//折线唯一标识，删除时使用
                            'styleId': 'style_blue',//绑定样式名
                            'paths': pl
                        }
                    ]
                });
            });
        },
    }
}
</script>

<style scoped>
.el-col {
    margin-bottom: 10px;
}
</style>
<style>
* {
    padding: 0px;
    margin: 0px;
}

#mapContainer {
    width: 100vw;
    height: 100vh;
}

.info_card {
    display: inline-block;
    margin: 50px auto;
    position: absolute;
    width: 200px;
    height: 100px;
    background-color: #c7c9c8;
    border: 5px solid #ffffff;
    color: #000000;
}

.info_card .title {
    width: 100%;
    height: 40px;
    background-color: #000000;
    color: #ffffff;
}

.title span.title_name {
    position: relative;
    top: 7px;
    left: 10px;
    font-size: 18px;
}


.info_card .title .close_img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
}

.info_card .title .close_img .min {
    width: 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
    border-width: 10px;
}

.info_card .title .close_img .top_img {
    border-style: solid dashed dashed;
    border-color: #000000 transparent transparent transparent;
    top: -2px;
}

.info_card .title .close_img .right_img {
    border-style: solid dashed dashed;
    border-color: transparent #000000 transparent transparent;
    left: 2px;
}

.info_card .title .close_img .bottom_img {
    border-style: solid dashed dashed;
    border-color: transparent transparent #000000 transparent;
    top: 2px;
}

.info_card .title .close_img .left_img {
    border-style: solid dashed dashed;
    border-color: transparent transparent transparent #000000;
    left: -2px;
}

.info_card span.cancle {
    width: 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    position: absolute;
}

.info_card span.bot {
    border-width: 20px;
    border-style: solid dashed dashed;
    border-color: #ffffff transparent transparent;
    left: 80px;
    bottom: -40px;
}

.info_card span.top {
    border-width: 20px;
    border-style: solid dashed dashed;
    border-color: #c7c9c8 transparent transparent;
    left: 80px;
    bottom: -33px;
}

.info_card .content {
    margin-top: 10px;
    margin-left: 10px;
}
</style>